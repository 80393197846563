import { Component } from 'react'
import './Boost.css'
import '../../css/Placeholder.css'
import '../../css/Modal.css'

import BoostsList from "../BoostsList.json"
import { format, format_decs} from '../utils'
import { CrazyCountdown } from '../CountDowns'



interface ModalBoostProps {
    type: string
    userData: any
    updateView: (view:string) => void
    buyBoost: (boostName:string) => void
    dailyCounter: any
}


export const ModalBoost = ({
    type = '',
    userData,
    updateView,
    buyBoost,
    dailyCounter
  }: ModalBoostProps): JSX.Element => {
    if (!type) return <></>
    var title = ''
    var header_content: any = ''
    var modalContent: any = ''
    var footer: any = ''

    
    var mutitapPrice: any = ''
    if (userData.clickValue < BoostsList.multitap.length+1) { 
      mutitapPrice = BoostsList.multitap.filter((boostD) => boostD.level === userData.clickValue)[0].price
    }

    var energyLimitPrice: any = ''
    if (userData.EnergyLimit/500 < BoostsList.energyLimit.length+1) { 
      energyLimitPrice = BoostsList.energyLimit.filter((boostD) => boostD.level === userData.EnergyLimit/500)[0].price
    }

    var energySpeedPrice: any = ''
    if (userData.EnergyPerDelay < BoostsList.energySpeed.length+1) { 
      energySpeedPrice = BoostsList.energySpeed.filter((boostD) => boostD.level === userData.EnergyPerDelay)[0].price
    }

    if (type === "Boost") {
        title='BOOST'
        header_content=(<><div className="about_boost">
          {
            (userData.language_code === "ru") ?
              ("КАК ЭТО РАБОТАЕТ?")
            :
              ("How does it work?")
          }
          </div></>)

        var counter: any = ''
        if (userData.nextCrazyEnergyTimestamp > Number(+new Date().getTime())/1000) {
          counter = <><CrazyCountdown nextTime={userData.nextCrazyEnergyTimestamp}/></>
        }
        if (userData.CrazyEnergyUsed >= 9) {
          counter = dailyCounter
        }

        modalContent = (<>
            <div className="boost__elems">
                {/* <div className="boost__elem crazy" {...((!counter) ? ({"onClick":() => updateView('CrazyBoost')}) : ({}))}> */}

                <div className="boost__elem crazy" onClick={() => updateView('CrazyBoost')}>
                <div className='icon'></div>
                {/* <svg xmlns="http://www.w3.org/2000/svg"></svg> */}
                <div className="boost__text_block">
                    <div className="boost__text_elem">
                    <div className="boost__text_title">
                        CRAZY ENERGY
                    </div>
                    <div className="boost__text_desc">
                    {
                      (userData.language_code === "ru") ?
                        ("ДОСТУПНО:")
                      :
                        ("AVAILABLE:")
                    }
                      <div className="boost_text_value">{9-userData.CrazyEnergyUsed}/9</div>
                    </div>
                    </div>
                    <div className="boost_crazy__counter">
                        {counter}
                    </div>
                </div>
                </div>
        
                <div className="boost__elem multitap" onClick={() => updateView('MultitapBoost')}>
                <div className='icon'></div>
                {/* <svg xmlns="http://www.w3.org/2000/svg"></svg> */}
                <div className="boost__text_block">
                    <div className="boost__text_elem">
                    <div className="boost__text_title">
                        MULTITAP
                    </div>
                    <div className="boost__text_desc">
                    {
                      (userData.language_code === "ru") ?
                        ("УРОВЕНЬ:")
                      :
                        ("LEVEL:")
                    }
                         <div className="boost_text_value">{userData.clickValue-1}</div>
                    </div>
                    </div>
                    {mutitapPrice ? <><div className="boost_price__elem">
                      <svg className="boost_price__img" xmlns="http://www.w3.org/2000/svg"></svg>
                      <div className="boost_price__value">
                        {format_decs(mutitapPrice)}
                      </div>
                    </div></> : <></>}
                </div>
                </div>
        
                <div className="boost__elem energy_limit" onClick={() => updateView('EnergyLimitBoost')}>
                <div className='icon'></div>
                {/* <svg xmlns="http://www.w3.org/2000/svg"></svg> */}
                <div className="boost__text_block">
                    <div className="boost__text_elem">
                    <div className="boost__text_title">
                        ENERGY LIMIT
                    </div>
                    <div className="boost__text_desc">
                    {
                      (userData.language_code === "ru") ?
                        ("УРОВЕНЬ:")
                      :
                        ("LEVEL:")
                    } <div className="boost_text_value">{userData.EnergyLimit/500-1}</div>
                    </div>
                    </div>
                    {energyLimitPrice ? <><div className="boost_price__elem">
                      <svg className="boost_price__img" xmlns="http://www.w3.org/2000/svg"></svg>
                      <div className="boost_price__value">
                        {format_decs(energyLimitPrice)}
                      </div>
                    </div></> : <></>}
                </div>
                </div>

                <div className="boost__elem energy_speed"  onClick={() => updateView('EnergySpeedBoost')}>
                    <div className='icon'></div>
                    <div className="boost__text_block">
                    <div className="boost__text_elem">
                        <div className="boost__text_title">
                        ENERGY SPEED
                        </div>
                        <div className="boost__text_desc">
                        {
                          (userData.language_code === "ru") ?
                            ("УРОВЕНЬ:")
                          :
                            ("LEVEL:")
                        } <div className="boost_text_value">{userData.EnergyPerDelay-1}</div>
                        </div>
                        </div>
                        {energySpeedPrice ? <><div className="boost_price__elem">
                          <svg className="boost_price__img" xmlns="http://www.w3.org/2000/svg"></svg>
                          <div className="boost_price__value">
                            {format_decs(energySpeedPrice)}
                          </div>
                        </div></> : <></>}

                    </div>
                </div>
            </div>
        </>);
        footer=''
    } else if (type === "CrazyBoost") {
        title=''
        header_content=(<>
        <div className='buyboost_header_elems'>
          <div className='buyboost_level_elem'>
            {
              (userData.language_code === "ru") ?
                ("ДОСТУПНО:")
              :
                ("AVAILABLE:")
            } <div className='buyboost_level_value'>{9-userData.CrazyEnergyUsed}/9</div>
          </div>
          <div className='buyboost_desc'>
            {
              (userData.language_code === "ru") ?
                (<>Восстановите энергию и сделайте<br/>еще один раунд добычи</>)
              :
                (<>Recover your energy and do another mining round mining.</>)
            }
          </div>
        </div>
        </>)
        var crazyPrices: any = {
          "0":0,
          "1":0,
          "2":0,
          "3":0,
          "4":0,
          "5":0,
          "6":0,
          "7":15000,
          "8":45000,
          "9":150000
        }

        var action_button: any = ''
        if (userData.EnergyLimit === userData.energyValue) {
          action_button = <><div className='buy_boost disable'>МАКС. ENERGY</div></>
        } else {
          if (userData.balance < crazyPrices[String(userData.CrazyEnergyUsed+1)]) {
            action_button = <><div className='buy_boost disable'>
              {
              (userData.language_code === "ru") ?
                ("НЕДОСТАТОЧНО МОНЕТ")
              :
                ("NOT ENOUGH COINS")
              }
              </div></>
          } else {
            action_button = <><div className='buy_boost' onClick={() => buyBoost("crazy_energy")}>
              {
              (userData.language_code === "ru") ?
                ("ИСПОЛЬЗОВАТЬ")
              :
                ("USE")
              }</div></>
          }
        }

        modalContent = (<>
            <div className='buyboost_footer'>
                <div className="buyboost_footer__button">
                {(userData.CrazyEnergyUsed > 5) ?
                (<>
                  <div className="buyboost__price_value_elem">
                      <svg xmlns="http://www.w3.org/2000/svg"></svg>
                      <div className="buyboost__price_value">
                      {format(crazyPrices[String(userData.CrazyEnergyUsed+1)])}
                      </div>
                  </div>
                </>
                )
                :
                (<></>)
                }
                
                {action_button}
                    <div className='back_boost' onClick={() => updateView("Boost")}>
                    {
                    (userData.language_code === "ru") ?
                      ("НАЗАД")
                    :
                      ("BACK")
                    }</div>
                </div>
            </div>
            </>)
        footer=(<>
            
            </>)
    } else if (type === "MultitapBoost") {
        title='MULTITAP'
        header_content=(<>
          <div className='buyboost_header_elems'>
          <div className='buyboost_level_elem'>
          {
          (userData.language_code === "ru") ?
            ("УРОВЕНЬ:")
          :
            ("LEVEL:")
          } <div className='buyboost_level_value'>{userData.clickValue-1}</div>
          </div>
          <div className='buyboost_desc'>
          {
            (userData.language_code === "ru") ?
              ("Увеличьте количество монет за 1 тап.")
            :
              ("Increase the amount of coins per 1 tap.")
          }
          </div>
        </div>
        </>
        )
        modalContent = (<>
            <div className='buyboost_footer'>
                {
                  (userData.language_code === "ru") ?
                    (<><div className="buyboost_footer__button">
                      {mutitapPrice ?<>
                      <div className="buyboost__price_value_elem">
                          <svg xmlns="http://www.w3.org/2000/svg"></svg>
                          <div className="buyboost__price_value">
                          {format(mutitapPrice)}
                          </div>
                      </div>
                      
                      <div {...((userData.balance >= mutitapPrice) ? ({onClick:() => buyBoost("multitap"), className:'buy_boost'}) : ({className:'buy_boost disable'}))}>{(userData.balance >= mutitapPrice) ? ("УЛУЧШИТЬ") : ("НЕДОСТАТОЧНО МОНЕТ")}</div></>  : <></>}
                      <div className='back_boost' onClick={() => updateView("Boost")}>НАЗАД</div>
                  </div></>)
                  :
                    (<><div className="buyboost_footer__button">
                    {mutitapPrice ?<>
                    <div className="buyboost__price_value_elem">
                        <svg xmlns="http://www.w3.org/2000/svg"></svg>
                        <div className="buyboost__price_value">
                        {format(mutitapPrice)}
                        </div>
                    </div>
                    
                    <div {...((userData.balance >= mutitapPrice) ? ({onClick:() => buyBoost("multitap"), className:'buy_boost'}) : ({className:'buy_boost disable'}))}>{(userData.balance >= mutitapPrice) ? ("IMPROVE") : ("NOT ENOUGH COINS")}</div></>  : <></>}
                    <div className='back_boost' onClick={() => updateView("Boost")}>BACK</div>
                </div></>)
                }
            </div>
            </>);
        footer=''


    } else if (type === "EnergyLimitBoost") {
      title='ENERGY LIMIT'
      header_content=(<>
        <div className='buyboost_header_elems'>
        <div className='buyboost_level_elem'>
        {
          (userData.language_code === "ru") ?
            ("УРОВЕНЬ:")
          :
            ("LEVEL:")
        } <div className='buyboost_level_value'>{userData.EnergyLimit/500-1}</div>
        </div>
        <div className='buyboost_desc'>
          {
            (userData.language_code === "ru") ?
              (<>Увеличьте количество энергии<br/>+500 энергии к уровню 1</>)
            :
              (<>Increase the amount of energy<br/>+500 energy to level 1.</>)
          }
          
        </div>
      </div>
      </>
      )
      modalContent = (<>
          <div className='buyboost_footer'>
          {
            (userData.language_code === "ru") ?
              (<><div className="buyboost_footer__button">
              {energyLimitPrice ?<> 
              <div className="buyboost__price_value_elem">
                  <svg xmlns="http://www.w3.org/2000/svg"></svg>
                  <div className="buyboost__price_value">
                  {format(energyLimitPrice)}
                  </div>
              </div> 
              <div  {...((userData.balance >= energyLimitPrice) ? ({onClick:() => buyBoost("energy_limit"), className:'buy_boost'}) : ({className:'buy_boost disable'}))}>{(userData.balance >= energyLimitPrice) ? ("УЛУЧШИТЬ") : ("НЕДОСТАТОЧНО МОНЕТ")}</div></>  : <></>}
              <div className='back_boost' onClick={() => updateView("Boost")}>НАЗАД</div>
          </div></>)
            :
            (<><div className="buyboost_footer__button">
            {energyLimitPrice ?<> 
            <div className="buyboost__price_value_elem">
                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                <div className="buyboost__price_value">
                {format(energyLimitPrice)}
                </div>
            </div> 
            <div  {...((userData.balance >= energyLimitPrice) ? ({onClick:() => buyBoost("energy_limit"), className:'buy_boost'}) : ({className:'buy_boost disable'}))}>{(userData.balance >= energyLimitPrice) ? ("IMPROVE") : ("NOT ENOUGH COINS")}</div></>  : <></>}
            <div className='back_boost' onClick={() => updateView("Boost")}>BACK</div>
        </div></>)
          } 
              
          </div>
          </>);
      footer=''
    } else if (type === "EnergySpeedBoost") {
      title='ENERGY SPEED'
      header_content=(<>
        <div className='buyboost_header_elems'>
        <div className='buyboost_level_elem'>
        {
          (userData.language_code === "ru") ?
            ("УРОВЕНЬ:")
          :
            ("LEVEL:")
        } <div className='buyboost_level_value'>{userData.EnergyPerDelay-1}</div>
        </div>
        <div className='buyboost_desc'>
        {
          (userData.language_code === "ru") ?
            (<>Увеличьте скорость восстановления энергии<br/>+1 ед. энергии к уровню 1</>)
          : 
            (<>Increase energy recovery rate<br/>+1 unit of energy to level 1.</>)
        }
        </div>
      </div>
      </>
      )
      modalContent = (<>
          <div className='buyboost_footer'>
          {
            (userData.language_code === "ru") ?
              (<><div className="buyboost_footer__button">
              {energySpeedPrice ?<> 
                <div className="buyboost__price_value_elem">
                    <svg xmlns="http://www.w3.org/2000/svg"></svg>
                    <div className="buyboost__price_value">
                    {format(energySpeedPrice)}
                    </div>
                </div> 
                <div  {...((userData.balance >= energySpeedPrice) ? ({onClick:() => buyBoost("energy_speed"), className:'buy_boost'}) : ({className:'buy_boost disable'}))}>{(userData.balance >= energySpeedPrice) ? ("УЛУЧШИТЬ") : ("НЕДОСТАТОЧНО МОНЕТ")}</div></>  : <></>}
                <div className='back_boost' onClick={() => updateView("Boost")}>НАЗАД</div>
            </div></>)
            :
            (<><div className="buyboost_footer__button">
            {energySpeedPrice ?<> 
              <div className="buyboost__price_value_elem">
                  <svg xmlns="http://www.w3.org/2000/svg"></svg>
                  <div className="buyboost__price_value">
                  {format(energySpeedPrice)}
                  </div>
              </div> 
              <div  {...((userData.balance >= energySpeedPrice) ? ({onClick:() => buyBoost("energy_speed"), className:'buy_boost'}) : ({className:'buy_boost disable'}))}>{(userData.balance >= energySpeedPrice) ? ("IMPROVE") : ("NOT ENOUGH COINS")}</div></>  : <></>}
              <div className='back_boost' onClick={() => updateView("Boost")}>BACK</div>
          </div></>)
          }

          </div>
          </>);
      footer=''
    }

    return (
      <>
      <div className='modal' onClick={() => updateView('')}>
        <div className={'modal-dialog '+type} onClick={e => e.stopPropagation()}>
  
          <div className='modal-header'>
            <span className='modal-close' onClick={() => updateView('')}>
              &times;
            </span>
            <svg className="modal__img" xmlns="http://www.w3.org/2000/svg"></svg>
            <div className='modal-title'>{title}</div>
            {/* {title_button && <div className='modal-title_button'>{title_button}</div>} */}
            {header_content && <div className='modal-header_content'>{header_content}</div>}
  
            
          </div>
          <div className='modal-body'>
            <div className='modal-content'>{modalContent}</div>
          </div>
          {footer && <div className='modal-footer'>{footer}</div>}
        </div>
      </div>
      </>
    )
  }




export class BOOST_SCREEN extends Component<{userData: any, updateUser: (boostName:string) => void, dailyCounter: any}> {

    state = {
      modalView: ''
    }

    update = (boostName:string) => {
      this.setState({modalView: ''})
      this.props.updateUser(boostName)
    }

    updateView = (view:string) => {
      if (view === "CrazyBoost") {
        if (this.props.userData.nextCrazyEnergyTimestamp > Number(+new Date().getTime())/1000) {
          return
        }
        if (this.props.userData.CrazyEnergyUsed >= 9) {
          return
        }
      }
      this.setState({modalView: view})
    }

    render() {

      return (
        <>
        <div>
          <div className="boosts__block" onClick={() => this.updateView("Boost")}>
              <div className="boosts_pic__elem">
                <svg className="boosts__img" xmlns="http://www.w3.org/2000/svg"></svg>
              </div>
            <div className="boosts__text">BOOST</div>
          </div>
          <ModalBoost
            type={this.state.modalView}
            userData={this.props.userData}
            updateView={this.updateView}
            buyBoost={this.update}
            dailyCounter={this.props.dailyCounter}
            />
        </div>
        </>
      );
    }
  }
  