import { Component, useState } from 'react'
import './Privacy.css'


const texts: any = {
    "ru":<>
    <h1>
    Политика конфиденциальности для мини приложений в телеграм
    </h1>
    <p>
        <em>
        Сентябрь 01, 2024
        </em>
    </p>
    <small>
    Эта страница информирует вас о наших политиках в отношении сбора, использования и раскрытия личной информации, когда вы используете наше Приложение.
    </small>
    <h1>
    1. Ключевые понятия
    </h1>
    <p>
    1.1. Разработчик: Физическое или юридическое лицо, которое публикует приложение на платформе и использует его в соответствии с условиями платформы. Информацию о Разработчике, такую как имя и другие данные, можно найти в разделе "Информация о разработчике" в настройках приложения.
    </p>
    <p>
    1.2. Приложение: Программный сервис, опубликованный Разработчиком на платформе, включенный в каталог приложений.
    </p>
    <p>
    1.3. Пользователь: Физическое лицо, которое зарегистрировалось и использует Приложение.
    </p>
    <p>
    1.4. Политика: Данная Политика конфиденциальности описывает, как обрабатывается и защищается информация о Пользователях.
    </p>
    <h1>
    2. Общие положения
    </h1>
    <p>
    2.1. Эта Политика является официальным документом Разработчика и определяет порядок обработки и защиты информации о физических лицах, использующих Приложения.
    </p>
    <p>
    2.2. Цель данной Политики — обеспечить надлежащую защиту информации о Пользователях, включая их персональные данные, от несанкционированного доступа и раскрытия.
    </p>
    <p>
    2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о Пользователях, регулируются данной Политикой и другими официальными документами Разработчика, а также применимым законодательством.
    </p>
    <p>
    2.4. Текущая версия данной Политики, которая является публичным документом, доступна любому Пользователю в интернете по гиперссылке.
    </p>
    <p>
    2.5. Разработчик имеет право вносить изменения в эту Политику. При внесении изменений Разработчик публикует новую версию Политики и обновляет дату "Вступления в силу" в верхней части Политики. Рекомендуется Пользователям регулярно проверять условия данной Политики на предмет изменений. Продолжение использования Приложения Пользователем после внесения изменений в эту Политику свидетельствует о принятии и согласии с такими изменениями.
    </p>
    <p>
    2.6. Используя Приложения, Пользователь соглашается с условиями этой Политики.
    </p>
    <p>
    2.7. Если Пользователь не согласен с условиями данной Политики, использование Приложений должно быть немедленно прекращено.
    </p>
    <h1>
    3. Условия использования Приложений
    </h1>
    <p>
    3.1. Предоставляя услуги по использованию Приложений, Разработчик разумно и добросовестно полагает, что Пользователь:
    <ul>
        <li>Имеет все необходимые права, позволяющие ему начать использовать Приложение.</li>
        <li>Предоставляет достоверную информацию о себе, как это требуется для использования Приложений.</li>
        <li>Понимает, что информация, переданная во время использования Приложений, может стать доступной другим Пользователям и может быть скопирована и распространена ими.</li>
        <li>Понимает, что некоторые виды информации, предоставленные другим Пользователям во время использования Приложений, не могут быть удалены самим Пользователем.</li>
        <li>Ознакомлен с данной Политикой, соглашается с ней и принимает на себя права и обязательства, указанные в ней.</li>
    </ul>
    </p>
    <p>
    3.2. Разработчик не проверяет достоверность информации, полученной (собранной) о Пользователях.
    </p>
    <h1>
    4. Цели обработки информации
    </h1>
    <p>
    Разработчик обрабатывает информацию о Пользователях, включая их персональные данные, для выполнения своих обязательств перед Пользователями в отношении использования Приложения.
    </p>
    <h1>
    5. Состав информации о Пользователях
    </h1>
    <p>
    Разработчик обрабатывает следующую информацию о Пользователях:
    </p>
    <ul>
        <li>Персональные данные, предоставленные с согласия Пользователя и необходимые для использования Приложений.</li>
        <li>Дополнительная информация о Пользователях, предоставленная администрацией платформы с согласия Пользователя.</li>
        <li>Информация, предоставленная Пользователями во время использования Приложений.</li>
        <li>Информация, полученная Разработчиком в результате действий Пользователя при использовании Приложений (например, IP-адреса, файлы cookie, статистика использования приложения).</li>
    </ul>
    <h1>
    6. Обработка персональных данных Пользователя 
    </h1>
    <p>
    6.1. Обработка персональных данных основывается на принципах:
    </p>
    <ul>
        <li>Законности и добросовестности обработки персональных данных.</li>
        <li>Соответствия целей обработки персональных данных целям, заранее определенным и заявленным во время их сбора.</li>
        <li>Соответствия объема и характера обработанных персональных данных заявленным целям обработки.</li>
        <li>Недопустимости объединения баз данных, содержащих персональные данные, для несовместимых целей.</li>
    </ul>
    <p>
    6.1.1. Условия и цели обработки персональных и других данных:
    </p>
    <ul>
        <li>Разработчик обрабатывает персональные и другие данные Пользователя с его согласия для предоставления услуг, связанных с использованием Приложений.</li>
    </ul>
    <p>
    6.1.2. Сбор персональных и других данных:
    </p>
    <ul>
        <li>Персональные данные и информация предоставляются администрацией платформы с согласия Пользователя при первом запуске и/или использовании Приложения.</li>
        <li>Дополнительная информация о Пользователе предоставляется администрацией платформы с согласия Пользователя посредством явного действия (например, нажатия кнопки "Разрешить") при первом запуске и/или использовании Приложения.</li>
        <li>Информация собирается Разработчиком с согласия Пользователя при первом запуске и/или использовании Приложения.</li>
    </ul>
    <p>
    6.1.3. Передача персональных данных:
    </p>
    <ul>
        <li>Персональные данные не передаются третьим лицам, кроме случаев, прямо предусмотренных данной Политикой. Передача персональных данных государственным органам осуществляется в соответствии с законом.</li>
    </ul>
    <p>
    6.2. Информация, такая как имя Пользователя, фото профиля и информация о его использовании, может быть доступна другим Пользователям Приложения.
    </p>
    <p>
    6.3. Персональные данные хранятся в течение всего времени использования Приложения и в течение периода, необходимого в соответствии с действующим законодательством после прекращения использования Приложения.
    </p>
    <h1>
    7. Права и обязанности Пользователей 
    </h1>
    <p>
    7.1. Пользователи имеют право на:
    </p>
    <ul>
        <li>Доступ к информации о себе через Приложения.</li>
        <li>Получение информации от Разработчика о обработке своих персональных данных по запросу.</li>
    </ul>
    <h1>
    8. Меры по защите информации Пользователей 
    </h1>
    <p>
    Разработчик принимает технические и организационные меры для обеспечения защиты персональных данных от несанкционированного или случайного доступа, уничтожения, изменения, блокировки, копирования, распространения, а также от других незаконных действий. Описание этих мер содержится во внутренних регламентах Разработчика.
    </p>
    <h1>
    9. Ограничения Политики
    </h1>
    <p>
    Настоящая Политика не применяется к действиям и ресурсам третьих лиц. Разработчик не несет ответственности за действия третьих лиц, которые получили доступ к информации Пользователя в силу особенностей платформы Приложения, доступной любому пользователю интернета. Пользователям рекомендуется внимательно относиться к объему информации, которую они распространяют.
    </p>
    <h1>
    10. Запросы Пользователей
    </h1>
    <p>
    10.1. Пользователи могут отправлять запросы касательно использования своих персональных данных Разработчику в письменной форме или в виде электронного документа, подписанного квалифицированной электронной подписью.
    </p>
    <p>
    10.2. Запросы должны содержать:
    </p>
    <ul>
        <li>Номер документа удостоверяющего личность Пользователя и данные выдавшего органа.</li>
        <li>Информацию, подтверждающую отношения Пользователя с Разработчиком.</li>
        <li>Подпись Пользователя или его представителя.</li>
    </ul>
    <p>
    10.3. Разработчик рассмотрит и ответит на запрос в течение 30 дней с момента его получения.
    </p>
    <p>
    10.4. Вся корреспонденция является конфиденциальной и не будет разглашена без письменного согласия Пользователя. Персональные данные и другая информация будут использоваться исключительно для ответа на запрос или в соответствии с требованиями закона.
    </p>
    </>,
    "en":<>
    <h1>
    Privacy Policy for Mini Apps in Telegram
    </h1>
    <p>
        <em>
        September 01, 2024
        </em>
    </p>
    <small>
    This page informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Application.    </small>
    <h1>
    1. Key Concepts
    </h1>
    <p>
    1.1. Developer: An individual or legal entity that publishes an application on the platform and uses it in accordance with the platform's terms. Information about the Developer, such as name and other details, can be found in the "Developer Information" section in the application settings.
    </p>
    <p>
    1.2. Application: A software service published by the Developer on the platform, included in the application catalog.
    </p>
    <p>
    1.3. User: An individual who has registered and is using the Application.
    </p>
    <p>
    1.4. Policy: This Privacy Policy describes how information about Users is processed and protected.
    </p>
    <h1>
    2. General Provisions
    </h1>
    <p>
    2.1. This Policy is an official document of the Developer and defines the procedures for processing and protecting information about individuals using the Applications.
    </p>
    <p>
    2.2. The purpose of this Policy is to ensure proper protection of information about Users, including their personal data, from unauthorized access and disclosure.
    </p>
    <p>
    2.3. Relations concerning the collection, storage, distribution, and protection of information about Users are governed by this Policy, other official documents of the Developer, and applicable legislation.
    </p>
    <p>
    2.4. The current version of this Policy, which is a public document, is available to any User on the internet via a hyperlink.
    </p>
    <p>
    2.5. The Developer has the right to make changes to this Policy. When changes are made, the Developer publishes a new version of the Policy and updates the "Effective Date" at the top of the Policy. Users are encouraged to regularly check the terms of this Policy for changes. Continued use of the Application by the User after changes to this Policy indicates acceptance and agreement with those changes.
    </p>
    <p>
    2.6. By using the Applications, the User agrees to the terms of this Policy.
    </p>
    <p>
    2.7. If the User does not agree with the terms of this Policy, the use of the Applications must be immediately discontinued.
    </p>
    <h1>
    3. Terms of Use of the Applications
    </h1>
    <p>
    3.1. In providing services for the use of the Applications, the Developer reasonably and in good faith assumes that the User:
    <ul>
        <li>Has all necessary rights to begin using the Application.</li>
        <li>Provides accurate information about themselves, as required for the use of the Applications.</li>
        <li>Understands that information transmitted during the use of the Applications may become available to other Users and may be copied and disseminated by them.</li>
        <li>Understands that certain types of information provided to other Users during the use of the Applications may not be deleted by the User themselves.</li>
        <li>Is familiar with this Policy, agrees to it, and assumes the rights and obligations specified herein.</li>
    </ul>
    </p>
    <p>
    3.2. The Developer does not verify the accuracy of the information obtained (collected) about Users.
    </p>
    <h1>
    4. Purposes of Information Processing
    </h1>
    <p>
    The Developer processes information about Users, including their personal data, to fulfill its obligations to Users regarding the use of the Application.
    </p>
    <h1>
    5. Information Composition about Users
    </h1>
    <p>
    The Developer processes the following information about Users:
    </p>
    <ul>
        <li>Personal data provided with the consent of the User and necessary for the use of the Applications.</li>
        <li>Additional information about Users provided by the platform administration with the consent of the User.</li>
        <li>Information provided by Users during the use of the Applications.</li>
        <li>Information obtained by the Developer as a result of the User's actions while using the Applications (e.g., IP addresses, cookies, application usage statistics).</li>
    </ul>
    <h1>
    6. Processing of User's Personal Data  
    </h1>
    <p>
    6.1. The processing of personal data is based on the principles of:
    </p>
    <ul>
        <li>Legality and good faith in the processing of personal data.  </li>
        <li>Conformity of the purposes of personal data processing to the purposes predetermined and stated at the time of collection.</li>
        <li>Conformity of the scope and nature of the processed personal data to the stated purposes of processing.</li>
        <li>Prohibition of combining databases containing personal data for incompatible purposes.</li>
    </ul>
    <p>
    6.1.1. Conditions and Purposes of Processing Personal and Other Data:
    </p>
    <ul>
        <li>The Developer processes the User's personal and other data with their consent to provide services related to the use of the Applications.</li>
    </ul>
    <p>
    6.1.2. Collection of Personal and Other Data:
    </p>
    <ul>
        <li>Personal data and information are provided by the platform administration with the User's consent at the first launch and/or use of the Application.</li>
        <li>Additional information about the User is provided by the platform administration with the User's consent through an explicit action (e.g., pressing the "Allow" button) at the first launch and/or use of the Application.</li>
        <li>Information is collected by the Developer with the User's consent at the first launch and/or use of the Application.</li>
    </ul>
    <p>
    6.1.3. Transfer of Personal Data:
    </p>
    <ul>
        <li>Personal data is not transmitted to third parties, except in cases directly provided for in this Policy. Transfer of personal data to government authorities is carried out in accordance with the law.</li>
    </ul>
    <p>
    6.2. Information such as the User's name, profile photo, and usage information may be accessible to other Users of the Application.
    </p>
    <p>
    6.3. Personal data is stored for the entire period of use of the Application and for the period necessary in accordance with applicable law after the cessation of the use of the Application.
    </p>
    <h1>
    7. Rights and Obligations of Users  
    </h1>
    <p>
    7.1. Users have the right to:
    </p>
    <ul>
        <li>Access information about themselves through the Applications.</li>
        <li>Receive information from the Developer regarding the processing of their personal data upon request.</li>
    </ul>
    <h1>
    8. Measures to Protect Users' Information
    </h1>
    <p>
    The Developer takes technical and organizational measures to ensure the protection of personal data from unauthorized or accidental access, destruction, alteration, blocking, copying, dissemination, and other illegal actions. A description of these measures is contained in the Developer's internal regulations.
    </p>
    <h1>
    9. Limitations of the Policy
    </h1>
    <p>
    This Policy does not apply to the actions and resources of third parties. The Developer is not responsible for the actions of third parties who gain access to User information due to the peculiarities of the Application platform available to any internet user. Users are encouraged to be cautious regarding the amount of information they share.
    </p>
    <h1>
    10. User Requests
    </h1>
    <p>
    10.1. Users may send requests regarding the use of their personal data to the Developer in written form or in the form of an electronic document signed with a qualified electronic signature.
    </p>
    <p>
    10.2. Requests must contain:
    </p>
    <ul>
        <li>The number of the document verifying the User's identity and details of the issuing authority.</li>
        <li>Information confirming the User's relationship with the Developer.</li>
        <li>The signature of the User or their representative.</li>
    </ul>
    <p>
    10.3. The Developer will consider and respond to the request within 30 days from the date of its receipt.
    </p>
    <p>
    10.4. All correspondence is confidential and will not be disclosed without the User's written consent. Personal data and other information will be used solely for the purpose of responding to the request or in accordance with legal requirement
    </p>
    </>
}

export default class PRIVACY_POLICY extends Component<{userData: any, updateView: (view:string) => void}, {}> {
    
    render() {
        console.log(this.props.userData.language_code)
        return (<>
        <div className='privacy'>
            <span className='privacy-close' onClick={() => this.props.updateView('SETTINGS')}>
            &times;
          </span>
        {texts[this.props.userData.language_code]}
        </div>
        </>)
    }
}
