import React, { Component, ReactNode, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import './Egg.css'
import '../../index.css'
import '../../css/Modal.css'
import DailyRewardJSON from '../DailyRewards.json'
import { format, format_decs} from '../utils'


var EGG_NAMES = new Map();
EGG_NAMES.set(1, {"name":"PINK EGG", "file_name":"pink"});
EGG_NAMES.set(2, {"name":"PURPLE EGG", "file_name":"purple"});
EGG_NAMES.set(3, {"name":"BLUE EGG", "file_name":"blue"});
EGG_NAMES.set(4, {"name":"GREEN EGG", "file_name":"green"});
EGG_NAMES.set(5, {"name":"BLACK EGG", "file_name":"black"});
EGG_NAMES.set(6, {"name":"YELLOW EGG", "file_name":"yellow"});
EGG_NAMES.set(7, {"name":"ORANGE EGG", "file_name":"orange"});
EGG_NAMES.set(8, {"name":"RED EGG", "file_name":"red"});
EGG_NAMES.set(9, {"name":"WHITE EGG", "file_name":"white"});
EGG_NAMES.set(10, {"name":"TIFFANY EGG", "file_name":"tiffany"});
EGG_NAMES.set(11, {"name":"GOLD EGG", "file_name":"gold"});


interface EGGProps {
  egg: number
  energyEnough: boolean
  canIClickPlease: boolean
  sleep: boolean
  funMode: boolean
  Mode: string
  clickValue: number
  userData: any
  cooldown: number
  handleClick(): void
  children?: ReactNode
}

interface BalanceProps {
  value: number
}

interface HeaderProps {
  name: string
  level:number
  profit: number
  userData: any
  updateView: (view:string) => void
}

interface LevelProps {
  level: number
  userData: any
}

interface ProfitPHProps {
  profit: number
  userData: any
}

interface EnergyProps {
  value: number
  limit: number
}

interface NumberInfo {
  id: string
  value: number
  x: number
  y: number
}


interface ModalProps {
  type: string
  visible: boolean
  userData: any
  onClose: () => void
  updateUser: () => void
}



export const Modal = ({
  type = '',
  visible = false,
  userData,
  onClose,
  updateUser
}: ModalProps): JSX.Element => {
  if (!visible) return <></>

  var title: any = ''
  var header_content: any = ''
  var content: any = ''
  var footer: any = ''

  if (type === "DailyReward") {
    if (userData.language_code === "ru") {
      title='ЕЖЕДНЕВНАЯ НАГРАДА'
    } else {
      title='DAILY REWARD'
    }

    var dailyRewards__elems: any = []
    var dailyRewards__elemClass = ''
    var date = new Date();
    var now = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
    var tomorrow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));

    var CanCollect = true
    if (Number(now.getTime())/1000 <= userData.last_daily_bonus_collection_timestamp && userData.last_daily_bonus_collection_timestamp < Number(tomorrow.getTime())/1000) {
      CanCollect = false
    }

    var DailyRewardDay = userData.DailyRewardDay
    var DailyRewardValue = 0
    DailyRewardJSON.forEach(function (d_elem, i) {
      dailyRewards__elemClass = ''
      if (CanCollect) {
        if (i < DailyRewardDay) {
          dailyRewards__elemClass = " checked"
        }

        if (i === DailyRewardDay) {
          dailyRewards__elemClass = " active"
          DailyRewardValue = d_elem['reward']
        }
      } else {
        if (i < DailyRewardDay-1) {
          dailyRewards__elemClass = " checked"
        }

        if (i === DailyRewardDay-1) {
          dailyRewards__elemClass = " active"
          DailyRewardValue = d_elem['reward']
        }
      }

      dailyRewards__elems.push(
      <div className={"dailyRewards__elem"+dailyRewards__elemClass}>
        <div className="dailyRewards__title">
          {d_elem['day']}
        </div>
        <div className="dailyRewards__desc">
          {(userData.language_code === "ru") ? ('ДЕНЬ') : ("DAY")}
        </div>

        <div className="dailyRewards__value_elem">
          <svg xmlns="http://www.w3.org/2000/svg"></svg>
          <div className="dailyRewards__value">
            +{format_decs(d_elem['reward'])}
          </div>
        </div>

      </div>
      )
    })

    const modalHeaderContent = (<>
      <div className="modal__desc">
        <div className="modal__desc_lvl1">
          {
            (userData.language_code === "ru") ?
              ("Награда для тех, кто ежедневно следит за своим EGG")
            :
              ("Reward for those who keep their EGG track daily")
          }
        </div>
        <div className="modal__desc_lvl2">
          {
            (userData.language_code === "ru") ?
              ("Если вы пропустите день, начнете все сначала")
            :
              ("If you miss a day, you start all over again")
          }
        </div>
      </div></>)

    const modalContent = (<>
      <div className="dailyRewards__elems">
        {dailyRewards__elems.map((object:any, i:any) => <>{object}</>)}
      </div>
      </>);

    if (CanCollect) {
      footer = (<>
        <div className='dailyRewards_footer'>
          <div className="dailyRewards_footer__button">
            <div className="dailyRewards__reward_value_elem">
              <svg xmlns="http://www.w3.org/2000/svg"></svg>
              <div className="dailyRewards__reward_value">
                {'+ '+format(DailyRewardValue)}
              </div>
            </div>
            <div className="dailyRewards_get__button" onClick={updateUser}>
              {
                (userData.language_code === "ru") ?
                  ("ПОЛУЧИТЬ")
                :
                  ("GET")
              }
            </div>
          </div>
        </div>
        </>);
      }
    header_content=modalHeaderContent
    content=modalContent

  } else if (type === "StartReward") {

    var reward = 0
    if (userData.is_premium) {
      title = <><div className="StartReward_Title">
        {
          (userData.language_code === "ru") ?
            (<>ПОЗДРАВЛЯЕМ ТЕБЯ,<br/>
            ТЫ ПРЕМИУМ ПОЛЬЗОВАТЕЛЬ TELEGRAM</>)
          :
            (<>CONGRATS, YOU ARE A PREMIUM TELEGRAM USER.</>)
        }
        </div></>
      reward = 25000
    } else {
      title = <><div className="StartReward_Title">
        {
          (userData.language_code === "ru") ?
            (<>ПОЗДРАВЛЯЕМ ТЕБЯ,<br/>
            ТЕПЕРЬ У ТЕБЯ ЕСТЬ СВОЙ EGG</>)
          :
            (<>CONGRATS, NOW YOU HAVE YOUR OWN EGG.</>)
        }
        </div></>
      reward = 7500
    }
    content = <>
    
    <div className="StartReward__content">
      <div className="StartReward__elem">
        <svg className="StartReward__img" xmlns="http://www.w3.org/2000/svg"></svg>
        <div className="StartReward__rewtext">
          +{format_decs(reward)}
        </div>
      </div>

      <div className="StartReward_footer__button">
        <div className='StartReward_get__button' onClick={onClose}>
        {
          (userData.language_code === "ru") ?
            (<>СПАСИБО, EGG</>)
          :
            (<>THANKS, EGG</>)
        }
          </div>
      </div>
    </div>
    </>
  } else if (type === "OfflineEarnings") {
    var rewardO: number = userData.offline_reward
    title = <><div className="StartReward_Title">
      {
        (userData.language_code === "ru") ?
          (<>ВЫ ЗАРАБОТАЛИ EGG</>)
        :
          (<>YOU HAVE GAINED EGG</>)
      }

    </div></>

    content = <>
      <div className="StartReward__content">
        <div className="StartReward__elem">
          <svg className="StartReward__img" xmlns="http://www.w3.org/2000/svg"></svg>
          <div className="StartReward__rewtext">
            +{format(rewardO)}
          </div>
        </div>

        <div className="StartReward_footer__button">
          <div className='StartReward_get__button' onClick={onClose}>{
          (userData.language_code === "ru") ?
            (<>СПАСИБО, EGG</>)
          :
            (<>THANKS, EGG</>)
        }</div>
        </div>
      </div>
    </>
  }

  return (
    <>
    <div className='modal' onClick={onClose}>
      <div className={'modal-dialog '+type} onClick={e => e.stopPropagation()}>

        <div className='modal-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          <svg className="modal__img" xmlns="http://www.w3.org/2000/svg"></svg>
          <div className='modal-title'>{title}</div>
          {/* {title_button && <div className='modal-title_button'>{title_button}</div>} */}
          {header_content && <div className='modal-header_content'>{header_content}</div>}

        </div>
        <div className='modal-body'>
          <div className='modal-content'>{content}</div>
        </div>
        {footer && <div className='modal-footer'>{footer}</div>}
      </div>
    </div>
    </>
  )
}



const numberAnimationDurationMs = 500
const numberAnimationDurationSec = numberAnimationDurationMs / 500

const notCoinAppearence = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0,
  },
  transition: {
    duration: 0.2,
    ease: [0, 0.71, 0.2, 9],
    scale: {
      type: 'spring',
      damping: 12,
      stiffness: 170
      // restDelta: 0.1,
    },
  },
}

const cooldownAppearence = {
  initial: {
    opacity: 0,
    scale: 0.7,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.5,
  },
  transition: {
    duration: 0.7,
  },
}


export const Header = ({
  name,
  level,
  profit,
  userData,
  updateView
}: HeaderProps): JSX.Element => {
return (
  <div className='header'>
    <div className='header_line'>
      <div className="logo_user-name__elem">
        <svg className="egg__logo" xmlns="http://www.w3.org/2000/svg"></svg>
        <div className="user-name__text">{name}</div>
      </div>
        <div className="settings-button" onClick={() => updateView('SETTINGS')}></div>
    </div>
    <div className="level_profit_block">
        <Level level={level} userData={userData}/>
        <ProfitPH profit={profit} userData={userData}/>
      </div>
  </div>
)
}


export const Level = ({
  level,
  userData
}: LevelProps): JSX.Element => {
  return (
    <div className="level_rank__elem">
      <div className="level_rank__text">
        <div className="level_rank_title__text">
          {
            (userData.language_code === "ru") ?
              ("УРОВЕНЬ")
            :
              ("LEVEL")
          }</div>
        {/* <div>{">"}</div> */}
      </div>

        <div className="level_rank_progress__elem">
          <div className="level_rank__value">{level} / 10</div>
            <div className="progress-container">
              <div className="progress-bar" style={{ width: `${level*10}%` }} />
            </div>
          </div>
        </div>
      
)
}


export const ProfitPH = ({
  profit,
  userData
}: ProfitPHProps): JSX.Element => {

return (
    <div className="profit_container">
      <div className="profit_per_hour__title">{
            (userData.language_code === "ru") ?
              ("ПРИБЫЛЬ В ЧАС")
            :
              ("PROFIT PER HOUR")
          }</div>
      
      <div className="profit_per_hour__block">
        <div className="profit_per_hour__elem">
          <svg className="profit_per_hour__img" xmlns="http://www.w3.org/2000/svg"></svg>
          <div className="profit__sum">{format(profit)}</div>
        </div>
      </div>
    </div>
)
}



export class DailyReward extends Component<{userData: any, getDailyReward: () => void}, {}> {

    state = {
      modalIsOpen: false
    }

    render() {
      const openModal = () => {
        this.setState({modalIsOpen: true})
      };
  
      const closeModal = () => {
        this.setState({modalIsOpen: false})
      };

      const updateUser = () => {
        this.setState({modalIsOpen: false})
        this.props.getDailyReward()
      }
  
      var date = new Date();
      var now = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
      var tomorrow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));
      var CanCollect = true
      if (Number(now.getTime())/1000 <= this.props.userData.last_daily_bonus_collection_timestamp && this.props.userData.last_daily_bonus_collection_timestamp < Number(tomorrow.getTime())/1000) {
        CanCollect = false
      }


  return (
    <>
    <div className="daily_reward" onClick={openModal}>
      <svg className="daily_reward__img" xmlns="http://www.w3.org/2000/svg"></svg>
        <div className="daily_card__text">
          
          {
            (this.props.userData.language_code === "ru") ? 
              (<><div className='card_text__title'>ЕЖЕДНЕВНАЯ<br/>НАГРАДА</div>{
                (CanCollect)
                ?
                (<div className='card_text__action glow'>ПОЛУЧИТЬ</div>)
                :
                (<div className='card_text__action done'>ПОЛУЧЕНО</div>)
              }</>)
            :
            (<><div className='card_text__title'>DAILY<br/>REWARD</div>{
              (CanCollect)
              ?
              (<div className='card_text__action glow'>CLAIM</div>)
              :
              (<div className='card_text__action done'>CLAIMED</div>)
            }</>)
          }

        </div>
    </div>
    <Modal 
      type="DailyReward"
      visible={this.state.modalIsOpen}
      userData={this.props.userData}
      onClose={closeModal}
      updateUser={() => updateUser()} />
    </>
)
}
}


export const DailyCypher = ({action, availableView, userData}:{action: () => void, availableView: boolean, userData: any}): JSX.Element => {
  return (
    <div className="daily_cypher" {...((availableView) ? ({onClick:() => action()}) : ({}))} >
      <svg className="daily_cypher__img" xmlns="http://www.w3.org/2000/svg"></svg>
        <div className="daily_card__text">
          {
          (userData.language_code === "ru") ? 
            (<><div className='card_text__title'>ЕЖЕДНЕВНЫЙ<br/>ШИФР</div>
            {
            (availableView) ?
             (<><div className="card_text__action glow">ВЗЛОМАТЬ</div></>) 
             : 
             (<><div className="card_text__action done">ВЗЛОМАНО</div></>) }
          </>)
          :
            (<><div className='card_text__title'>DAILY<br/>CIPHER</div>
            {
            (availableView) ?
             (<><div className="card_text__action glow">HACK</div></>) 
             : 
             (<><div className="card_text__action done">HACKED</div></>) }
          </>)
          }
      </div>
    </div>
)
}




interface DailyComboProps {
  updateView: (view:string) => void
  countdown: any
  userData: any
}

export const DailyCombo = ({updateView, countdown, userData}: DailyComboProps): JSX.Element => {
  return (
    <div className="daily_combo" onClick={() => updateView('UPGRADE')}>
      <svg className="daily_combo__img" xmlns="http://www.w3.org/2000/svg"></svg>
        <div className="daily_card__text">
          <div className='card_text__title'>{
            (userData.language_code === "ru") ?
              ("КОМБО")
            :
              ("COMBO")
          }
          </div>
          <div className='card_text__counter'>{countdown}</div>
        </div>
    </div>
)
}



export const Balance = ({
  value
}: BalanceProps): JSX.Element => {
  return (
    <div className="balance__block">
        <svg className="balance__coin" xmlns="http://www.w3.org/2000/svg"></svg>
        <div id="balance__value" className="balance__value">{format(value)}</div>
    </div>
  )
}


export const EGG = ({
  egg,
  energyEnough,
  canIClickPlease,
  sleep,
  funMode,
  Mode,
  clickValue,
  userData,
  cooldown,
  children,
  handleClick
}: EGGProps): JSX.Element => {
  const notCoinRef = useRef<HTMLParagraphElement>(null)
  const [buttonTransform, setButtonTransform] = useState({
    scale: 1,
    translateZ: 0,
    rotateX: 0,
    rotateY: 0,
  })
  const [numbers, setNumbers] = useState<NumberInfo[]>([])
  
  const handleTouchStart = (event: any) => {
    handleClick()
    if (notCoinRef.current) {
      const touch = event.touches[0]
      const rect = notCoinRef.current.getBoundingClientRect()
      const centerX = rect.left + rect.width / 2
      const centerY = rect.top + rect.height / 2

      const offsetX = touch.clientX - centerX
      const offsetY = centerY - touch.clientY

      const rotateXValue = offsetY * 0.01
      const rotateYValue = offsetX * 0.01

      setButtonTransform({
        scale: 1,
        translateZ: -5,
        rotateX: rotateXValue,
        rotateY: rotateYValue,
      })

      const randomNumberBetweenTenAndMinusTen =
        Math.floor(Math.random() * 21) - 10

      const newNumber: NumberInfo = {
        id: `${Date.now()}`,
        value: clickValue,
        x: touch.clientX + randomNumberBetweenTenAndMinusTen,
        y: touch.clientY
      }

      setNumbers((prevNumbers) => [...prevNumbers, newNumber])

      // Remove the number after the animation duration
      setTimeout(() => {
        setNumbers((prevNumbers) =>
          prevNumbers.filter((number) => number.id !== newNumber.id)
        )
      }, numberAnimationDurationMs)
    }
  }
  const handleTouchEnd = () => {
    setButtonTransform({
      scale: 1,
      translateZ: 0,
      rotateX: 0,
      rotateY: 0,
    })
  }

  const egg_stages: any = {
    "1":"base",
    "2":"light",
    "3":"crack",
    "4":"dragon"
  }

  console.log(userData.game_stage)

  var egg_stage: any = "base"
  if (Number(userData.game_stage) < 4 && Number(userData.game_stage) > 0) {
    egg_stage = egg_stages[String(userData.game_stage)]
    console.log(egg_stage)
  }


  return (
    
    <AnimatePresence mode="popLayout">
      {canIClickPlease ? (
        <motion.div className="root" key="1" {...notCoinAppearence}>
          <div
            className={cn("container")}
          >
            {children}
            <div
              ref={notCoinRef}
              className={cn("egg", egg_stage, (Mode==="CYPHER" ? "mode-cypher" : ""), EGG_NAMES.get(egg)["file_name"])}
              {...((energyEnough) ? ({onTouchStart:handleTouchStart}) : ({}))}
              // onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              style={{
                transform: `
                scale(${buttonTransform.scale})
                translateZ(${buttonTransform.translateZ}px)
                rotateX(${buttonTransform.rotateX}deg)
                rotateY(${buttonTransform.rotateY}deg)
              `,
              }}
            ></div>
          </div>
          <div>
            <AnimatePresence>
              {numbers.map((number) => {
                return (
                  <motion.div
                    key={number.id}
                    className="clickAmount"
                    initial={{ opacity: 1, y: number.y - 50, x: number.x }}
                    animate={{ opacity: 0, y: number.y - 200 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: numberAnimationDurationSec }}
                  >
                    {number.value}
                  </motion.div>
                )
              })}
            </AnimatePresence>
          </div>
        </motion.div>
      ) : (
        <motion.div className="root" key="2" {...cooldownAppearence}>
          <div className="cooldownContainer">
            <div className="cooldownNumber">
              {cooldown || <small>Wait...</small>}
            </div>
            <svg className="cooldown">
              <circle
                className="cooldownCircle"
                r="140"
                cx="150"
                cy="150"
              ></circle>
            </svg>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}


export const Energy = ({
  value,
  limit
}: EnergyProps): JSX.Element => {
  return (
    <div className="energy__block">
        <div className="energy_pic__elem">
          <svg className="energy__img" xmlns="http://www.w3.org/2000/svg"></svg>
        </div>
      <div className="energy__text">{format(value)} / {format(limit)}</div>
    </div>
  )
}


interface StartHeaderProps {
  name: string
}

export const StartHeader = ({
    name
  }: StartHeaderProps): JSX.Element => {

  return (
    <div className='header'>
      <div className='header_line'>
        <div className="logo_user-name__elem">
          <svg className="egg__logo" xmlns="http://www.w3.org/2000/svg"></svg>
          <div className="user-name__text">{name}</div>
        </div>
      </div>
    </div>
  )
}



interface MyState {
  egg_id: number
}

export class CHOOSE_EGG extends Component<{onEGGidChange : any, userData: any}, MyState> {
  constructor(props: any) {
    super(props);
    this.handleSTART = this.handleSTART.bind(this);
  }

  state = {
    egg_id: 1
  }

  handlePrevious(): void {
    if (this.state.egg_id === 1) {
      this.setState({
        egg_id: EGG_NAMES.size
      })
    } else {
      this.setState({
        egg_id: this.state.egg_id - 1
      })
    }
  }

  handleNext(): void {
    if (this.state.egg_id === EGG_NAMES.size) {
      this.setState({
        egg_id: 1
      })
    } else {
      this.setState({
        egg_id: this.state.egg_id + 1
      })
    }
  }

  handleSTART(): void {
    this.props.onEGGidChange(this.state.egg_id);
  }
  
  render() {
    return (
    <>
    {/* <StartHeader name={this.props.userData.name}/> */}
    <div className="choose_egg__block">
      <div className="choose_egg__text">
          <div className="choose_egg__title">ВЫБЕРИ СВОЕ EGG</div>
          <div className="choose_egg__description"></div>
      </div>
        <div className="eggs__block">
          <div className={cn("egg_pic", "base", EGG_NAMES.get(this.state.egg_id)["file_name"])}></div>
        </div>
        <div className="change_egg_btns__block">
          <div className="change_egg_btns">
            <div
              className="previousEgg"
              onClick={() => this.handlePrevious()}
              // style={{
              //   transform: `
              //   scale(${1})
              //   translateZ(${1}px)
              // `,
              // }}
            ><div className="arrow-left"></div></div>
            <div className="eggName">{EGG_NAMES.get(this.state.egg_id)["name"]}</div>
            <div
              className="nextEgg"
              onClick={() => this.handleNext()}
              // style={{
              //   transform: `
              //   scale(${1})
              //   translateZ(${1}px)
              // `,
              // }}
            ><div className="arrow-right"></div></div>
          </div>

          <div className="start__block">
            <div className="start__btn" onClick={() => this.handleSTART()}>НАЧАТЬ</div>
          </div>
        </div>
    </div>
  </>

  )
}
}