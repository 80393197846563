import { Component } from 'react'
import './Loading.css'


export default class LOADING_DATA extends Component<{userData: any}, {}> {
  render() {
    return (
      <>
        <div className="loading__block">
          <div className="loading__title">
            THE PROJECT WAS<br/>CREATED ON THE TON<br/>NETWORK
          </div>
          <div className="loading__footer">
            <div className="loading_social_elems">
                <div className="loading_social_elem_wrap telegram">
                    <div className="loading_social_elem">
                        <svg className='pic' xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                </div>
                <div className="loading_social_elem_wrap x">
                    <div className="loading_social_elem">
                      <svg className='pic' xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                </div>
                <div className="loading_social_elem_wrap discord">
                    <div className="loading_social_elem">
                      <svg className='pic' xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                </div>
                <div className="loading_social_elem_wrap youtube">
                    <div className="loading_social_elem">
                      <svg className='pic' xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                </div>
            </div>
            <div className="loading_text loading">{
              (this.props.userData.language_code === "ru") ? 
              ("ЗАГРУЗКА . . .")
              :
              ("LOADING  . . .")
            }</div>
          </div>
        </div>
      </>
    )
  }
}