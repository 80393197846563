export function format(value: number) {
    let res
    // (value.indexOf(',') !== -1) ? res : new Intl.NumberFormat('ru-RU')
  
    res = new Intl.NumberFormat('ru-RU').format(value)
    return res;
  }
  
  
export function format_decs(num: number) {
    const digits = 3
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];

    // "/\.([0]+$)|(\.?:+[0-9]*[1-9])0+$)/"
    
    // const regexp = new RegExp("(\.0+$|(\.?:+[0-9]*[1-9])?0+$)");
    const regexp = new RegExp("((\.?:+[0-9]*[1-9])?0+$)");
    const regexpLastDot = new RegExp("\.$");
    // @ts-ignore
    const item = lookup.findLast(item => num >= item.value);
    // @ts-ignore
    var d = (num / item.value).toFixed(digits).replace(regexp, "")
    return item ? (d[d.length - 1] === "." ? d.replace(".", "") : d).concat(item.symbol) : "0";
  }


export function copyTextToClipboard (text:string) {
  try {
    navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Ошибка:', err);
    
  }
};