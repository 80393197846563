import { useState } from 'react'
import './Upgrade.css'
import '../EGG/Egg.css'
import '../../css/Placeholder.css'
import { format, format_decs} from '../utils'
import SkillsUpgrades from "../SkillsUpgrades.json"
import { api } from '../../App'



interface CongratsComboProps {
  visible: any
  comboData: any
  comboCount: number
  userData: any
  onClose: () => void
}

const CongratsCombo = ({visible, comboData, comboCount, userData, onClose}:CongratsComboProps) => {
  if (!visible) return <></>

  if (comboCount !== 3) {
    return (<>
    <div className='modal' onClick={onClose}>
      <div className={'modal-combo_dialog'} onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          <div className={"modal_up_card__img combo_congrats_pic"}></div>
            {
              (userData.language_code === "ru") ?
              (<div className='modal_up_card_grats-title'>{(comboCount === 1) ? ("КРУТО, ПЕРВАЯ КАРТА ЕСТЬ! НЕ ОСТАНАВЛИВАЙСЯ!") : ("КРУТО, ВТОРАЯ КАРТА ЕСТЬ! НЕ ОСТАНАВЛИВАЙСЯ!")}</div>)
              :
              (<div className='modal_up_card_grats-title'>{(comboCount === 1) ? ("PERFECT, WE HAVE OUR FIRST CARD! DON'T STOP!") : ("PERFECT, WE HAVE OUR SECOND CARD! DON'T STOP!")}</div>)
            }
          <div className='modal_up_card-lvl_elem'>
            <div className='modal_up_card-lvl_text'>{
              (userData.language_code === "ru") ?
                ("КОМБО:")
                :
                ("COMBO:")
              }
              </div>
            <div className='modal_up_card-lvl_value'>{comboCount} / 3</div>
          </div>
        </div>
          <div className='modal-combo_footer'>
            <div className="modal-combo_footer__button">
              <div className='modal-combo_get__button' onClick={onClose}>
              {
              (userData.language_code === "ru") ?
                ("УРА!")
                :
                ("HOORAY!")
              }</div>
            </div>
          </div>
      </div>
    </div>
  </>)
  } else {
    return (<>
      <div className='modal' onClick={onClose}>
        <div className={'modal-combo_dialog'} onClick={e => e.stopPropagation()}>
          <div className='modal-header'>
            <span className='modal-close' onClick={onClose}>
              &times;
            </span>
            <div className={"modal_up_card__img combo_congrats_pic"}></div>
            <div className='modal_up_card_grats-title'>
              {
              (userData.language_code === "ru") ?
                ("КОМБО СОБРАНО И ВЫ ВОЗНАГРАЖДЕНЫ!")
                :
                ("THE COMBO IS COLLECTED AND YOU HAVE A REWARD!")
              }</div>
            <div className='modal_up_card_grats-reward_elem'>

                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                  +
                <div className='modal-combo_profit__value'>
                  {format_decs(comboData)}
                </div>
            </div>
            <div className='modal_up_card-grats_elem'>
              <div className='modal_up_card-grats_text'>{
              (userData.language_code === "ru") ?
                ("КОМБО:")
                :
                ("COMBO:")
              }</div>
              <div className='modal_up_card-grats_value'>{comboCount} / 3</div>
            </div>
          </div>
            <div className='modal-combo_footer'>
              <div className="modal-combo_footer__button">
                <div className='modal-combo_get__button grats' onClick={onClose}>
                {
                (userData.language_code === "ru") ?
                  ("ЗАБРАТЬ!")
                  :
                  ("GET!")
                }</div>
              </div>
            </div>
        </div>
      </div>
    </>)
  }
}


interface ModalProps {
  upgradeID: string
  visible: boolean
  userData: any
  state: any
  onSubmit: (upgradeID:string) => void
  onClose: () => void
}

const UpgradeModal = ({
  upgradeID = '',
  visible = false,
  userData = '',
  state = '',
  onSubmit,
  onClose,
}: ModalProps): JSX.Element => {

  var modal_up_card__img:any = ''

  userData.photoToUpgrades.forEach((element:any) => {
    if ((element.src).includes(`/${upgradeID}.`)) {
      modal_up_card__img = <>
      <img className="modal_up_card__img" src={element.src} alt="" />
    </>
    }
  });

  if (!visible) return <></>

  var upgradeUserData: any = (Object.entries(userData.upgrades).filter(([k, v]) => k === upgradeID));
  var level = 0
  if (upgradeUserData.length !== 0) {
    level = upgradeUserData[0][1]
  }

  var DT:any = ''
  if (!upgradeID) {
    DT = userData.all_upgrades[state.modalCollection][upgradeID]
  } else {
    DT = userData.all_upgrades[state.modalCollection][upgradeID]
  }

  if (level === DT["levels"].length) return <></>

  return (
    <>
    <div className='modal' onClick={onClose}>
      <div className={'modal-combo_dialog'} onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          {modal_up_card__img}
          
          <div className='modal_up_card-title'>{DT.name}</div>
          <div className='modal_up_card-lvl_elem'>
            <div className='modal_up_card-lvl_text'>
              {(userData.language_code === "ru") ? ("УРОВЕНЬ:") : ("LEVEL:")}
              
              </div>
            <div className='modal_up_card-lvl_value'>{level}</div>
          </div>
          <div className='modal_up_card-description'>{DT.desc}</div>
        </div>
        <div className='modal-body'>
          <div className='modal-content'>
            <div className='modal-combo_profit__block'>
              <div className='modal-combo_profit__text'>
              {(userData.language_code === "ru") ? ("ПРИБЫЛЬ В ЧАС") : ("PROFIT PER HOUR")}
                
              </div>
              <div className='modal-combo_profit__elem'>
                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                +
                <div className='modal-combo_profit__value'>
                  {format_decs(DT["levels"][level]["profit"])}
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className='modal-combo_footer'>
            <div className="modal-combo_footer__button">
              <div className="modal-combo__value_elem">
                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                <div className="modal-combo__reward_value">
                  {format(DT["levels"][level]["price"])}
                </div>
              </div>

              <div {...((userData.balance >= DT["levels"][level]["price"]) ? ({onClick:() => onSubmit(upgradeID), className:"modal-combo_get__button"}) : ({className:"modal-combo_get__button disabled"}) )}>
                {(userData.language_code === "ru") ? ((userData.balance >= DT["levels"][level]["price"]) ? ('УЛУЧШИТЬ') : ('Недостаточно средств')) : (userData.balance >= DT["levels"][level]["price"]) ? ('IMPROVE') : ('Not enough coins')}
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  )
}



interface UPGRADEprops {
  balance: number
  userData: any
  buyUpgrade: (res:any) => void
  syncSession: () => void
  openCongrats: () => void
  photoToUpgrades: any
}

export default function UPGRADE({balance, userData, buyUpgrade, syncSession, openCongrats, photoToUpgrades}:UPGRADEprops): JSX.Element  {
  const upgradeData: any = []
  const [ModalView, setModalView] = useState({
    modalIsOpen: false,
    modalView: '',
    mainView: '',
    modalCollection: 'MINING',
    congratsCombo: false,
  })

  const [comboData, setComboData] = useState('')
  var comboClassNames = new Map()
  comboClassNames.set(0, "unknown_pic")
  comboClassNames.set(1, "unknown_pic")
  comboClassNames.set(2, "unknown_pic")

  var upgrades_id_list: any = []
  var upgrades_full_list: any = {}

  Object.entries(userData.all_upgrades).forEach(([cl, d]:[any, any]) => {
    upgrades_id_list = [...upgrades_id_list, ...Object.keys(d)]
    Object.entries(d).forEach(([id , data]) => {
      var d_to: any = data
      d_to["upgrade_class"] = cl
      upgrades_full_list[id] = d_to
    })
  })
  if (userData.DailyCombo.length > 0) {
    userData.DailyCombo.forEach((DC:any, i:number) => {
      if (upgrades_id_list.includes(DC)) {
        comboClassNames.set(i, DC)
      }
    })
  }

  const openModal = (modalView:string) => {
    setModalView({
      modalIsOpen: true,
      modalView: modalView,
      mainView: ModalView.mainView,
      modalCollection: ModalView.modalCollection,
      congratsCombo: false
    })
  };

  const closeModal = () => {
    setModalView({
      modalIsOpen: false,
      modalView: '',
      mainView: ModalView.mainView,
      modalCollection: ModalView.modalCollection,
      congratsCombo: false
    })
  };

  const closeCongrats = () => {
    setModalView({
      modalIsOpen: ModalView.modalIsOpen,
      modalView: ModalView.modalView,
      mainView: ModalView.mainView,
      modalCollection: ModalView.modalCollection,
      congratsCombo: ModalView.congratsCombo
    })
  };

  const updateView = () => {
    setModalView({
      modalIsOpen: ModalView.modalIsOpen,
      modalView: ModalView.modalView,
      mainView: ModalView.mainView,
      modalCollection: ModalView.modalCollection,
      congratsCombo: ModalView.congratsCombo
    })
  };

  const updateMainView = (view:string) => {
    setModalView({
      modalIsOpen: ModalView.modalIsOpen,
      modalView: ModalView.modalView,
      mainView: view,
      modalCollection: ModalView.modalCollection,
      congratsCombo: ModalView.congratsCombo
    })
  };

  const updatemodalCollectionView = (view:string) => {
    setModalView({
      modalIsOpen: ModalView.modalIsOpen,
      modalView: ModalView.modalView,
      mainView: ModalView.mainView,
      modalCollection: view,
      congratsCombo: ModalView.congratsCombo
    })
  };
  
  const submitButton = (upgradeID:string) => {
    // var DT = SkillsUpgrades.filter( function(data){ return data.id === upgradeID });
    if (!upgrades_id_list.includes(upgradeID)) {
      console.log('ERROR')
      setModalView({
        modalIsOpen: false,
        modalView: '',
        mainView: ModalView.mainView,
        modalCollection: ModalView.modalCollection,
        congratsCombo: false
      })
      return
    }

    var upgradeUserData: any = (Object.entries(userData.upgrades).filter(([k, v]) => k === upgradeID));
    var level = 0
    if (upgradeUserData.length !== 0) {
      level = upgradeUserData[0][1]
    }

    if (level === upgrades_full_list[upgradeID].levels.length) {
      console.log('ERROR')
      setModalView({
        modalIsOpen: false,
        modalView: '',
        mainView: ModalView.mainView,
        modalCollection: ModalView.modalCollection,
        congratsCombo: false
      })
      return
    }

    if (userData.balance < upgrades_full_list[upgradeID].levels[level].price) {
      console.log('ERROR')
      setModalView({
        modalIsOpen: false,
        modalView: '',
        mainView: ModalView.mainView,
        modalCollection: ModalView.modalCollection,
        congratsCombo: false
      })
    } else {
      var out = ''

      if (!userData.sync) {
        syncSession()
      }
      api.post('/api/store/upgrade', {
          "upgrade_id": upgradeID,
          "upgrade_class":upgrades_full_list[upgradeID]["upgrade_class"]
        }).then(res => {
          buyUpgrade(res)
          if (res.data.has_collected_combo_part) {
            if (res.data.daily_combo_collected.length === 3) {
              setComboData(res.data.combo_reward)
            } else {
              setComboData("GratsCombo")
            }
          }
          openCongrats()
          setModalView({
            modalIsOpen: false,
            modalView: '',
            mainView: ModalView.mainView,
            modalCollection: ModalView.modalCollection,
            congratsCombo: res.data.has_collected_combo_part
          })
        }).catch(err => {
          console.log(err)
        })
      }
  };


  if (userData.all_upgrades[ModalView.modalCollection]) {

    Object.entries(userData.all_upgrades[ModalView.modalCollection]).forEach(([uid, Ob]:[any, any]) => {
      // var levelRaw = userData.upgrades.filter(function(data:any) { return data.id === uid} )
      var levelRaw: any = (Object.entries(userData.upgrades).filter(([k, v]) => k === uid));
      var level = 0

      if (levelRaw.length !== 0) {
        level = levelRaw[0][1]
      }
      var comboProfit_value: string
      var comboPrice_value: string
      var comboPrice_text = ''
      if (level < Ob["levels"].length) {
        comboProfit_value = `+ ${format_decs(Ob["levels"][level]["profit"])}`
        comboPrice_value = `${format_decs(Ob["levels"][level]["price"])}`
      } else {
        var comboProfit_num = 0
        Ob["levels"].forEach((DC:any) => {
          comboProfit_num+=DC.profit
        })

        comboProfit_value = `${format_decs(comboProfit_num)}`
        comboPrice_value = ''
        if (userData.language_code === "ru") {
          comboPrice_text = 'Достигнут макc. уровень'
        } else {
          comboPrice_text = 'Max. level'
        }
      }

      var LevelUpgradeContent = <>
          <div className='combo__lvl_block'>
            <div className='combo__lvl_text'>
              {(userData.language_code === "ru") ?
              ("УР:")
              :
              ("LVL:")
              }
              </div>
            <div className='combo__lvl_value'>{level}</div>
          </div>
          <div className='combo_price__elem'>
            <svg xmlns="http://www.w3.org/2000/svg"></svg>
            <div className='combo_price__value'>{comboPrice_value}</div>
          </div>
        </>
      var combo_pic: any = ''
      userData.photoToUpgrades.forEach((element:any) => {
        if ((element.src).includes(`/${uid}.`)) {
          combo_pic = <>
          <img className="combo_pic" src={element.src} alt="" />
        </>
        }
      });

      upgradeData.push(<>
      <div className='combo_elem' {...((comboPrice_value) ? ({onClick:() => openModal(uid)}) : undefined )}>
        <div className='combo__top_block'>
          {combo_pic}
          <div className='combo_title__block'>
            <div className='combo_title__text'>
            {Ob["name"]}
            </div>
            <div className='combo_profit__block'>
              <div className='combo_profit__text'>
              {(userData.language_code === "ru") ?
                ("Прибыль в час:")
                :
                ("Profit per hour:")
                }
              </div>
              <div className='combo_profit__elem'>
                {comboProfit_value && <svg xmlns="http://www.w3.org/2000/svg"></svg>}
                
                <div className='combo_profit__value'>
                  {comboProfit_value}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='combo__bottom_block'>
          <div className='combo__bottom_elems'>
            {comboPrice_text && <div className='combo_price__text'>{comboPrice_text}</div>}
            {comboPrice_value && LevelUpgradeContent}
          </div>
        </div>
      </div>

      </>)
    });
  }

  var combo_pic_1: any = <svg className={comboClassNames.get(0)} xmlns="http://www.w3.org/2000/svg"></svg>
  var combo_pic_2: any = <svg className={comboClassNames.get(1)} xmlns="http://www.w3.org/2000/svg"></svg>
  var combo_pic_3: any = <svg className={comboClassNames.get(2)} xmlns="http://www.w3.org/2000/svg"></svg>

  if (comboClassNames.get(0) != "unknown_pic") {
    userData.photoToUpgrades.forEach((element:any) => {
      if ((element.src).includes(`/${comboClassNames.get(0)}.`)) {
        combo_pic_1 = <>
        <img className={comboClassNames.get(0)} src={element.src} alt="" />
      </>
      }
    });
  }
  if (comboClassNames.get(1) != "unknown_pic") {
    userData.photoToUpgrades.forEach((element:any) => {
      if ((element.src).includes(`/${comboClassNames.get(1)}.`)) {
        combo_pic_2 = <>
        <img className={comboClassNames.get(1)} src={element.src} alt="" />
      </>
      }
    });
  }
  if (comboClassNames.get(2) != "unknown_pic") {
    userData.photoToUpgrades.forEach((element:any) => {
      if ((element.src).includes(`/${comboClassNames.get(2)}.`)) {
        combo_pic_3 = <>
        <img className={comboClassNames.get(2)} src={element.src} alt="" />
      </>
      }
    });
  }

  return (
          <>
          <div className='combo_back_block'>
            <div className='combo__block'>
              <div className='combo__block_header'>
                <div className='combo_guess__block'>
                  <div className='combo_guess__header'>
                    <div className='combo_guess__title'>
                      <div className='combo_guess__title_value'>{(userData.DailyCombo).length} / 3</div>
                      <div className='combo_guess__title_text'>
                      {(userData.language_code === "ru") ?
                        ("КОМБО")
                        :
                        ("COMBO")
                        }
                        </div>
                    </div>
                    <div className='combo_guess__value_block'>
                      <svg xmlns="http://www.w3.org/2000/svg"></svg>
                      <div className='combo_guess__value'>
                        +{format(userData.ComboСurrentReward)}
                      </div>
                    </div>
                  </div>
                  <div className='combo_guess__main'>
                    <div {...((comboClassNames.get(0) === "unknown_pic") ? ({className:'combo_guess'}) : ({className:'combo_guess grats'}))}>
                      {combo_pic_1}
                    </div>
                    <div {...((comboClassNames.get(1) === "unknown_pic") ? ({className:'combo_guess'}) : ({className:'combo_guess grats'}))}>
                      {combo_pic_2}
                      {/* <svg className={comboClassNames.get(1)} xmlns="http://www.w3.org/2000/svg"></svg> */}
                    </div>
                    <div {...((comboClassNames.get(2) === "unknown_pic") ? ({className:'combo_guess'}) : ({className:'combo_guess grats'}))}>
                      {combo_pic_3}
                      {/* <svg className={comboClassNames.get(2)} xmlns="http://www.w3.org/2000/svg"></svg> */}
                    </div>
                  </div>
                </div>
                <div className="combo_balance__block">
                  <div className="balance__block">
                    <svg xmlns="http://www.w3.org/2000/svg"></svg>
                    <div id="balance__value" className="balance__value">{format(balance)}</div>
                  </div>
                </div>
                <div className="upgrade_menu">
                  <div {...(ModalView.modalCollection === "MINING") ? ({className: "upgrade_menu_item care active"}) : ({className: "upgrade_menu_item care", onClick:() => updatemodalCollectionView('MINING')})}>
                  <div>MINING</div>
                  </div>
                  {/* <div {...(ModalView.mainView === "first") ? ({className: "upgrade_menu_item care active"}) : ({className: "upgrade_menu_item care", onClick:() => updateMainView('first')})}>
                  <div>MINING</div>
                  </div> */}
                  {/* <div {...(ModalView.mainView === "second") ? ({className: "upgrade_menu_item skills active"}) : ({className: "upgrade_menu_item skills", onClick:() => updateMainView('second')})}>
                  <div>MAGIC</div>
                  </div> */}
                  <div {...(ModalView.modalCollection === "CRYPTO") ? ({className: "upgrade_menu_item upgrade active"}) : ({className: "upgrade_menu_item upgrade", onClick:() => updatemodalCollectionView('CRYPTO')})}>
                  <div>CRYPTO</div>
                  </div>
                </div>
              </div>
              <div className="combo__block_data">
                {upgradeData.map((object:any, i:any) => <>{object}</>)}
              </div>
            </div>
          </div>
          <UpgradeModal
                upgradeID={ModalView.modalView}
                visible={ModalView.modalIsOpen}
                userData={userData}
                state={ModalView}
                onSubmit={submitButton}
                onClose={closeModal} />
          <CongratsCombo visible={ModalView.congratsCombo} comboData={comboData} comboCount={userData.DailyCombo.length} userData={userData} onClose={closeModal}/>
    </>
          )
}


// export default function UPGRADE_SCREEN({updateView}: {updateView: () => void})  {
//     return (
//       <>
//         <div className="placeholder__block">
//           <div className="placeholder__text">
//             Скоро откроется
//           </div>
//         </div>
//         <Menu updateView={updateView} activeScreen={'UPGRADE'}/>
//       </>
//     )
//   }