import { Component } from 'react'
import './Start.css'
import '../../css/Placeholder.css'


export default class START_DATA extends Component<{updateView: (view: string) => void, userData: any}, {}> {

  state = {
    screenView: 0
  }
  ru_descriptions: any = [
    <>Это игра, в которой вы развиваете своего EGG,<br/>зарабатываете игровые монеты и, в конечном<br/>итоге, получаете настоящий токен с реальной<br/>ценностью на бирже. Более того, вы сможете<br/>зарабатывать уже существующую на бирже<br/>монету TON во время игры и использовать ее по<br/>своему усмотрению, не дожидаясь листинга<br/>игровой монеты EGG.</>,
    "Основные способы заработка игровой монеты EGG включают: кликать по экрану, сражаться и прокачивать своего EGG.",
    "Вы можете прокачивать своего EGG, чтобы повысить пассивный почасовой доход и начать зарабатывать прямо во время игры",
    <>Приглашайте друзей присоединиться к игре, и<br/>вы будете зарабатывать процент от их доходов.<br/>Вам начисляется процент от ваших друзей до<br/>целых 10 уровней в глубину. Это позволит вам<br/>быстрее и эффективнее накапливать<br/>виртуальные монеты EGG.</>,
    "Чем дальше вы продвигаетесь в игре, тем больше будет ваш эирдроп токенов EGG и тем больше вы заработаете токенов TON уже во время игры.",
    "Внимательно следите за своим EGG, чтобы он не потерял свои силы и мощность, иначе ваш EGG будет утрачен и вам придется начать сначала.",
  ]
  en_descriptions: any = [
      "This is a game in which you develop your EGG, earn game coins and eventually get a real token with the exchange value. Moreover, you will be able to earn TON coin already existing on the exchange during the game and use it at your own request without waiting for the EGG game coin to be listed.",
      "The main ways to earn EGG in-game coin include clicking on the screen, battling and upgrading your EGG.",
      "You can pump your EGG to increase your passive hourly income and start earning right while playing the game.",
      "Invite your friends to join the game and you will earn a percentage of their income. You get a share of credits from your friends up to as much as 10 levels. This allows you to accumulate virtual EGG coins faster and more efficiently.",
      "The further you progress in the game, the bigger your eirdrop of EGG tokens will be and the more tokens you will earn TON tokens during the game.",
      "Follow your EGG attentively so that it does not lose its strength and power, otherwise your EGG will be lost and you will have to start over.",
  ]
  
  btn_text: any = {
    "next":{
      "ru":"ДАЛЕЕ",
      "en":"NEXT"
    },
    "skip":{
      "ru":"ПРОПУСТИТЬ",
      "en":"SKIP"
    }
  }
  

  nextScreen = () => {
    if (this.state.screenView === 5) {
      this.props.updateView('EGG')
    } else {
      this.setState({
        screenView: this.state.screenView + 1
      })
    }
  }

  skipScreen = () => {
      this.props.updateView('EGG')
  }

  render() {
    return (
      <>
        <div className="start_elems">

            <div className={'start_pic pic_'+String(this.state.screenView)}></div>

            <div className='start_desc'>
              {
                this.props.userData.language_code === "ru" ?
                (this.ru_descriptions[this.state.screenView])
                :
                (this.en_descriptions[this.state.screenView])
              }
            </div>

            <div className="start_footer">
                <div className='progress_bar'>
                    <ul id="progressbar">
                    <li {...(this.state.screenView === 0) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 1) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 2) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 3) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 4) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 5) ? ({className:"active"}) : ('')}></li>
                    </ul>
                </div>
            </div>
            

            <div className="next_button" onClick={() => this.nextScreen()}>{
            this.props.userData.language_code === "ru" ?
            (this.btn_text["next"]["ru"])
            :
            (this.btn_text["next"]["en"])
            }</div>
            {(this.props.userData.egg === 0) ? (<></>) : (<><div className="skip_button" onClick={() => this.skipScreen()}>{
            this.props.userData.language_code === "ru" ?
            (this.btn_text["skip"]["ru"])
            :
            (this.btn_text["skip"]["en"])
            }</div></>)}
        </div>
    </>
    )
  }
}