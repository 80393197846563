// LOADING
import Image1 from './static/loading/loading_bg.webp'
import Image2 from './static/loading/telegram_icon.png'
import Image3 from './static/loading/discord_icon.png'
import Image4 from './static/loading/x_icon.png'
import Image5 from './static/loading/youtube_icon.png'
import Image6 from './static/loading/star_icon.png'
import Image7 from './static/loading/plus_icon.png'
import Image8 from './static/start/start_bg.png'

// EGGS
import Image9 from './static/eggs/base/black.webp'
import Image10 from './static/eggs/base/blue.webp'
import Image11 from './static/eggs/base/green.webp'
import Image12 from './static/eggs/base/orange.webp'
import Image13 from './static/eggs/base/pink.webp'
import Image14 from './static/eggs/base/purple.webp'
import Image15 from './static/eggs/base/red.webp'

// START
import Image16 from './static/start/first.webp'
import Image17 from './static/start/second.webp'
import Image18 from './static/start/third.webp'
import Image19 from './static/start/fourth.webp'
import Image20 from './static/start/fifth.webp'
import Image21 from './static/start/sixth.webp'
import Image22 from './static/loading/copy_icon.png'

// import Image16 from './static/start/first.png'
// import Image17 from './static/start/second.png'
// import Image18 from './static/start/third.png'
// import Image19 from './static/start/fourth.png'
// import Image20 from './static/start/fifth.png'
// import Image21 from './static/start/sixth.png'
// import Image22 from './static/loading/copy_icon.png'

// BOOST
import Image23 from './static/boost/crazy.png'
import Image24 from './static/boost/energy_limit.png'
import Image25 from './static/boost/energy_speed.png'
import Image26 from './static/boost/multitap.png'

// FRIENDS
import Image27 from './static/friends/friends.webp'
import Image28 from './static/friends/friends_empty.webp'

// MENU
import Image29 from './static/menu/assignments_icon.svg'
import Image30 from './static/menu/battles_icon.svg'
import Image31 from './static/menu/friends_icon.svg'
import Image32 from './static/menu/improvements_icon.svg'
import Image33 from './static/menu/mining_icon.svg'
import Image34 from './static/menu/wallet_icon.svg'

// TASKS
import Image35 from './static/tasks/task_action_done_icon.svg'
import Image36 from './static/tasks/task_action_ready_icon.svg'
import Image37 from './static/tasks/task_bybit.png'
import Image38 from './static/tasks/task_daily.png'
import Image39 from './static/tasks/task_discord.png'
import Image40 from './static/tasks/task_guess.png'
import Image41 from './static/tasks/task_telegram.png'
import Image42 from './static/tasks/task_x.png'
import Image43 from './static/tasks/task_youtube.png'
import Image44 from './static/tasks/tasks_icon.png'
import Image45 from './static/tasks/daily_cypher_icon.webp'
import Image46 from './static/tasks/daily_combo_icon.webp'

// MAIN
import Image47 from './static/coin.webp'
import Image48 from './static/boost/boosts.webp'


export const preloadSrcList = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  Image21,
  Image22,
  Image23,
  Image24,
  Image25,
  Image26,
  Image27,
  Image28,
  Image29,
  Image30,
  Image31,
  Image32,
  Image33,
  Image34,
  Image35,
  Image36,
  Image37,
  Image38,
  Image39,
  Image40,
  Image41,
  Image42,
  Image43,
  Image44,
  Image45,
  Image46,
  Image47,
  Image48
]


export function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}


// export const preloadImage = (src) => {
//     return new Promise(resolve => {
//       const image = new Image();
//       image.src = "any";
//       image.onload = () => {
//         store.dispatch(addSavedimage([src, image])); // <-- dispatch img element itself
//         resolve();
//         // this.loadedImages.tile = image; //this.loadedImages is an instance variable
//         // resolve(image);
//       };
      

//     });
//   };


export const preloadImages = () => {
    const promises = preloadSrcList.map((src) => preloadImage(src));
    return Promise.all(promises);
  };


