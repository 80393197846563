import { Component, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
// import styles from './MyComponent.module.css';
import './Settings.css'
import '../../css/Placeholder.css'
import '../../css/Modal.css'
import { api } from '../../App'


const Modal = ({view, userData, changeView, updateUsername, deleteUser}: {view:any, userData:any, changeView: (view:any) => void, updateUsername: (name:any) => void, deleteUser: () => void}) => {
  var title: any = ''
  var footer: any = ''
  var content: any = ''

  const [nameText, setNameText] = useState('');
  function namechangefunc(event:any) {
    setNameText(event.target.value)
  }

  function changeViewWrapper(view: any) {
    changeView(view)
    setNameText("")
  }

  function updateUserName() {
  }


  if (view === 0) {
    return (<></>)
  } else if (view === "change_nickname") {
    if (userData.language_code === "ru") {
      title = 'ИЗМЕНИТЬ НИКНЕЙМ'
      content = <>
      <input onChange={namechangefunc} className={'modalTaskInput'} placeholder='Введите новое имя...'></input>
      </>
    } else {
      title = 'CHANGE NICKNAME'
      content = <>
      <input onChange={namechangefunc} className={'modalTaskInput'} placeholder='Enter new nickname...'></input>
      </>
    }

    footer = <>
        <div {...((nameText.length === 0) ? ({className:"modalSettings_btn save disable"}) : ({className:"modalSettings_btn save", onClick:() => updateUserName()}))}>
          <div className="modalSettings-save-text">
            {
              (userData.language_code === "ru") ? 
              ("СОХРАНИТЬ")
              :
              ("SAVE")
            }
          </div>
        </div>
      </>
  } else if (view === "delete_account") {
    if (userData.language_code === "ru") {
      title = 'УДАЛИТЬ АККАУНТ'
    } else {
      title = 'DELETE ACCOUNT'
    }
    content = <>
    </>
    footer = <>
      <div className="modalSettings_btns__block">
        <div className="modalSettings_btn delete" onClick={() => deleteUser()}>
          <div className="modalSettings-text">
            {
              (userData.language_code === "ru") ? 
              ("УДАЛИТЬ")
              :
              ("DELETE")
            }
            
          </div>
        </div>
        <div className="modalSettings_btn cancel" onClick={() => changeViewWrapper(0)}>
          <div className="modalSettings-text">
            {
              (userData.language_code === "ru") ? 
              ("ОТМЕНА")
              :
              ("CANCEL")
            }
          </div>
        </div>
      </div>
    </>
  }
  var data_to_show = (<>
    <div className='modal' onClick={() => changeViewWrapper(0)}>
      {/* @ts-ignore */}
      <div className={cn('modalSettings-dialog', view)} onClick={e => e.stopPropagation()}>
        <div className='modalSettings-header'>
          <span className='modal-close' onClick={() => changeViewWrapper(0)}>
            &times;
          </span>
          <div className='modalSettings-title'>{title}</div>
        </div>
        <div className='modalSettings-body'>
          <div className='modalSettings-content'>{content}</div>
        </div>
        {footer && <div className='modalSettings-footer'>{footer}</div>}
      </div>
    </div>
  </>)
  return data_to_show
}



export default class SETTINGS_DATA extends Component<{userData: any, updateUserLang: (lang: string) => void, updateView: (view: string) => void, updateUserName: (new_name:string) => void, deleteUser: (res_data:any) => void}, {}> {
    state = {
      screenView: 0
    }

    changeView = (view:any) => {
      this.setState({
        screenView:view
      })
    }

    mailto = (mail: string) => {
      window.open(`mailto:${mail}`, '_blank');
    }

    updateUserLang = (event:any) => {
      this.props.updateUserLang(event.target.value)
    }

    updateUsername = (new_name:string) => {
      this.props.updateUserName(new_name)
    }

    deleteUser = () => {
      api.post('/api/user/delete').then(res => {
        this.props.deleteUser(res.data)
      }).catch(err => {
        this.props.deleteUser("error")
      })
    }

    render() {
        return (
          (this.props.userData.language_code === "ru") ? 
          
          (<>
            <div className='settings_block'>
              <div className='top_block'>
                  <div className='top_buttons'>
                    <div className='top_button delete_account' onClick={() => this.changeView('delete_account')}>Удалить аккаунт</div>
                  </div>
              </div>
              
              <div className='bottom_block'>
                  <div className='bottom_desc'>
                      <div className='support_text'>Служба поддержки:</div>
  
                      <a className='support_email' href="mailto:egg_game@gmail.com">egg_game@gmail.com</a>
                      <div className='privacy_policy' onClick={() => this.props.updateView('PRIVACY_POLICY')}>Политика конфиденциальности</div>
                  </div>
  
  
                  <div className='bottom_tr_buttons'>
                    <div className="switch">
                        <input id="toggle-on" className="toggle toggle-left" name="toggle" value="ru" type="radio" checked/>
                        <label htmlFor="toggle-on" className="btn">RU</label>
                        <input id="toggle-off" className="toggle toggle-right" name="toggle" value="en" type="radio" onClick={this.updateUserLang}/>
                        <label htmlFor="toggle-off" className="btn">EN</label>
                    </div>
                  </div>
              </div>
            </div>
  
            <Modal view={this.state.screenView} userData={this.props.userData} changeView={this.changeView} updateUsername={this.updateUsername} deleteUser={this.deleteUser}/>
            </>)
          :
          
          (<>
            <div className='settings_block'>
              
              <div className='top_block'>
                  <div className='top_buttons'>
                    <div className='top_button delete_account' onClick={() => this.changeView('delete_account')}>Delete account</div>
                  </div>
              </div>
              
              <div className='bottom_block'>
                  <div className='bottom_desc'>
                      <div className='support_text'>Support:</div>
                        <a className='support_email' href="mailto:egg_game@gmail.com">egg_game@gmail.com</a>
                      <div className='privacy_policy' onClick={() => this.props.updateView('PRIVACY_POLICY')}>Privacy Policy</div>
                  </div>

                  <div className='bottom_tr_buttons'>
                    <div className="switch">
                      <input id="toggle-off" className="toggle toggle-left" name="toggle" value="ru" type="radio" onClick={this.updateUserLang}/>
                      <label htmlFor="toggle-off" className="btn">RU</label>
                      <input id="toggle-on" className="toggle toggle-right" name="toggle" value="en" type="radio" checked/>
                      <label htmlFor="toggle-on" className="btn">EN</label>
                    </div>
                  </div>
              </div>
            </div>
  
            <Modal view={this.state.screenView} userData={this.props.userData} changeView={this.changeView} updateUsername={this.updateUsername} deleteUser={this.deleteUser}/>
            </>)
          
        )}
}